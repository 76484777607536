<template>
  <v-row v-if="orderData !== {} && orderData !== undefined">
    <v-col sm="12" md="6" class="overview__order-info ma-0">
      <div class="py-1 d-flex info-line"><p class="info-line__title float-left mb-0 mr-5">Číslo objednávky</p><p class="float-left mb-0"><a :href="orderData.adminUrl" target="_blank"> {{orderData.code}} </a></p></div>
      <div class="py-1 d-flex info-line"><p class="info-line__title float-left mb-0 mr-5">Čas vytvoření</p><p class="float-left mb-0"> {{new Date(orderData.creationTime).toLocaleString()}} ({{getDayPassed(orderData.creationTime)}} dnů) </p></div>
      <div class="py-1 d-flex info-line"><p class="info-line__title float-left mb-0 mr-5">Čas poslední změny</p><p class="float-left mb-0"> {{new Date(getLastChangeDate).toLocaleString()}} ({{getDayPassed(getLastChangeDate)}} dnů) </p></div>
      <div class="py-1 d-flex info-line"><p class="info-line__title float-left mb-0 mr-5">Stav</p><p class="float-left mb-0" v-if="orderData.status"> {{orderData.status.name}} </p></div>
      <div class="py-1 d-flex info-line"><p class="info-line__title float-left mb-0 mr-5">Platba</p><p class="float-left mb-0" v-if="orderData.paymentMethod"> {{orderData.paymentMethod.name}} </p></div>
      <div class="py-1 d-flex info-line">
        <p class="info-line__title float-left mb-0 mr-5">Doprava</p>
        <p class="float-left mb-0" v-if="orderData.shipping">
          {{orderData.shipping.name}}
          <span v-if="!isShipMethodPaired()" class="warning--text caption ml-5">
            <v-icon class="warning--text">mdi-alert-rhombus</v-icon>
            Dopravce není napárovaný v Automatizaci Objednávek
          </span>
          <span v-if="getLabelService" class="caption">{{`( ${getLabelService.service_type} ${ orderData.shipping.balikobot || ''})`}}</span>
        </p></div>
    </v-col>
    <v-col sm="12" md="6" class="overview__order-info ma-0">
        <div class="py-1 d-flex info-line"><p class="info-line__title float-left mb-0 mr-5">Poznámka</p><p class="float-left mb-0" v-if="orderData.notes" v-html="getSanitized(orderData.notes.customerRemark)"> </p></div>
        <div class="py-1 d-flex info-line"><p class="info-line__title float-left mb-0 mr-5">Poznámka eshopu</p><p class="float-left mb-0" v-if="orderData.notes" v-html="getSanitized(orderData.notes.eshopRemark)"> </p></div>
        <div class="py-1 d-flex info-line">
          <p class="info-line__title float-left mb-0 mr-5">Číslo balíku</p>
          <p class="float-left mb-0" v-if="orderData.notes">
            <a :href="orderData.notes.trackingUrl" target="_blank">{{orderData.notes.trackingNumber}}</a>
          </p>
        </div>
        <div class="py-1 d-flex info-line">
          <p class="info-line__title float-left mb-0 mr-5">Zaplacená</p>
          <p class="float-left mb-0">
            <v-chip :color="orderData.paid === true ? 'success' : 'error'" small>{{orderData.paid === true ? 'ANO' : 'NE'}}</v-chip>
          </p>
        </div>
        <div class="py-1 d-flex info-line"><p class="info-line__title float-left mb-0 mr-5">IČ</p><p class="float-left mb-0"> {{orderData.billingAddress.companyId || orderData.companyId}} </p></div>
        <div class="py-1 d-flex info-line">
          <p class="info-line__title float-left mb-0 mr-5">DIČ <span class="caption">(vatId)</span></p><p class="float-left mb-0"> {{orderData.billingAddress.vatId || orderData.vatId}} </p>
          <v-spacer></v-spacer>
          <v-divider vertical></v-divider>
          <p class="info-line__title float-left mb-0 ml-5 mr-5">DIČ <span class="caption">(taxId)</span></p><p class="float-left mb-0"> {{orderData.billingAddress.taxId }} </p>
          <v-spacer></v-spacer>
        </div>
        
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'OrderInfo',
    props: {
      order: { type: Object, default: null }
    },
    computed: {
      ...mapGetters(['getDbHistory', 'getBalikobotData', 'getShippingMethods', 'getLabelService', 'getBalikobotAlerts']),
      orderData() {
        return this.order.data;
      },

      orderAlerts() {
        return this.order.alerts;
      },
      getLastChangeDate() {
        if(this.getDbHistory.order.length > 0) return this.getDbHistory.order[0].data.changeTime;
        else return this.order.creationTime;
      },
    },
    methods: {
      getDayPassed(originalDate) {
        const today = new Date();
        const orderTime = new Date(originalDate)
        const differenceUnix = today.getTime() - orderTime.getTime();
        return Math.round(differenceUnix / (1000 * 3600 * 24))
      },
      isShipMethodPaired(){
          if(this.getLabelService) {
            return !this.getBalikobotAlerts.balikobot_delivery_method_not_paired;
          } else {
            return !this.orderAlerts.balikobot_delivery_method_not_paired;
          }
      },
      getSanitized(text) {
        if(text === null) return null;
        return text.replace( /(<([^>]+)>)/ig, '')
                   .replace(/\n{1,}/g, ' <br/>');
      },
    }
  }
</script>

<style scoped>

  .eshop-table > .v-data-table__wrapper > table > tbody > tr >  td {
    font-size: 14px !important;
  }

  .info-line {
    border-bottom: thin solid #e6e9ed;
  }

  .info-line__title{
    width: 150px;
    font-weight: bold;
  }
</style>
