export default class Auth {

    constructor(store) {
        this.store = store;
    }

    isLoggedIn() {
        if(localStorage.getItem('authToken') !== null) {
          return true;
        } else {
          return false;
        }
    }

    loginWithIsToken(token) {
      return new Promise( resolve => {
          localStorage.setItem('authToken', token);
          resolve();
      })
    }

    getToken() {
        return localStorage.getItem('authToken');
    }
}

Auth.install = (Vue, options) => {
    const auth = new Auth(options.store);
    Vue.$auth = auth;
    Vue.prototype.$auth = auth;
};
