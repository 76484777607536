<template >
  <div>
    <h2>Obsah objednávky:</h2>
    <v-data-table
      v-if="!displayRaw"
      :headers="headers"
      hide-default-footer
      hide-default-header
      sort-by="code"
      :sort-desc="true"
      :items="products"
      :items-per-page="-1"
      item-key="">

      <template slot="header" :headers="headers">
        <thead>
          <tr>
            <th v-for="(h, i) in headers" :key="h.text">
              <span v-if="i < 3">{{h.text}}</span>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  {{h.text}} <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                </template>
                <span v-if="h.value !== 'stock_current'">V čase: {{new Date(getOrderDetail.stock_synced_at).toLocaleString()}}</span>
                <span v-else>V čase: {{new Date().toLocaleString()}}</span>
              </v-tooltip>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item.stock_at_order="{ item }">
        <div v-for="stock in joinPastStockInfo(item.code)" :key="stock.id">
          {{stock.name}} <strong>{{stock.amount}} ks</strong>
        </div>
      </template>

      <template v-slot:item.stock_current="{ item }">
        <div v-for="stock in joinCurrentStockInfo(item.code)" :key="stock.id">
          {{stock.name}} <strong>{{stock.amount}} ks</strong>
        </div>
      </template>

      <template v-slot:item.availability_at_order="{ item }">
        {{getAvailability(item.code)}}
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'OrderItems',
    props: {
      products: Array,
    },
    data: () => ({
      displayRaw: false,
      headers: [
        { text: 'Kód', value: 'code', sortable: false },
        { text: 'Množství', value: 'amount', sortable: false },
        { text: 'Název', align: 'start', value: 'name', sortable: false},
        { text: 'Skladovost v okamžiku přijetí', value: 'stock_at_order', sortable: false },
        { text: 'Dostupnost v době přijetí', value: 'availability_at_order', sortable: false },
        { text: 'Aktuální skladovost', value: 'stock_current', sortable: false },
      ],
    }),
    mounted() {
    },
    computed: {
      ...mapGetters(['getItems', 'getOrderDetail']),
    },
    methods: {
      joinPastStockInfo(code) {
        const index = this.getItems.map(i => i.variant_code).indexOf(code);
        if(index > -1) return this.getItems[index].stock_order_time;
      },
      joinCurrentStockInfo(code) {
        const index = this.getItems.map(i => i.variant_code).indexOf(code);
        if(index > -1) return this.getItems[index].stock_now;
      },
      getAvailability(code) {
        const index = this.getItems.map(i => i.variant_code).indexOf(code);
        if(index > -1) return this.getItems[index].availability_order_time;
      },
    }
  }
</script>

<style scoped>
</style>
