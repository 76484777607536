<template>
  <div class="text-center">
    <h1>Přihlašování...</h1>
  </div>
</template>

<script>
export default {
  name: 'Login',
  mounted() {
    if(this.$route.query.jwt !== undefined) this.loginUser();
  },
  computed: {
    eshopID() { return this.$route.params.eshopID },
    orderID() { return this.$route.params.orderID },
  },
  methods: {
    loginUser() {
      this.$auth.loginWithIsToken(this.$route.query.jwt).then(() => {
          this.$router.push({name: 'Home', params: {eshopID: this.eshopID, orderID: this.orderID} })
      });
    }
  }
}
</script>

<style lang="css" scoped>
</style>
