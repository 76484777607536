import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import NotLogged from '@/views/NotLogged.vue'

import Login from '@/views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/notLogged',
    name: 'NotLogged',
    component: NotLogged
  },
  {
    path: '/login/:eshopID?/:orderID?',
    name: 'Login',
    component: Login
  },
  {
    path: '/:eshopID?/:orderID?',
    name: 'Home',
    component: Home,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
