import Vue from 'vue'
import Vuex from 'vuex'

import OrderDetail from './orderDetail'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    OrderDetail,
  }
})
