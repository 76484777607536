<template>
  <div>
    <div class="card-list mb-8">
      <Filters v-if="getEshops.length > 0"/>
    </div>

    <div class="card-list">
      <v-tabs
        v-model="activeTab"
        fixed-tabs
        background-color="#2a3f54"
        class="rounded rounded-b-0"
        dark>
        <v-tab to="#overview">Přehled</v-tab>
        <v-tab to="#timeline"><v-icon class="mr-4">mdi-clock-time-five-outline</v-icon>Časová osa změn</v-tab>
        <v-tab to="#timelineBalikobot" :disabled="getTrackingDisabled">
          <v-icon class="mr-4">mdi-clock-time-five-outline</v-icon>Historie trackingu zásilky
        </v-tab>
        <v-tab to="#orderRules" v-if="getEshopModules.order_rules"><v-icon class="mr-4">mdi-cog-transfer-outline</v-icon>Aplikovaná pravidla v Automatizaci Objednávek</v-tab>
      </v-tabs>

      <v-progress-linear v-if="isLoadingData" indeterminate height="7"/>
      <v-card v-if="isLoadingData" class="pa-16 text-center font-weight-bold">
        <v-row>
          <v-col><v-skeleton-loader type="text@5" /></v-col>
          <v-col><v-skeleton-loader type="text@5" /></v-col>
        </v-row>
      </v-card>

      <v-tabs-items v-model="activeTab" :touchless="true" v-if="!error && !isLoadingData">
          <v-tab-item value="overview">
             <Overview/>
          </v-tab-item>
          <v-tab-item value="timeline">
             <OrderTimeline/>
          </v-tab-item>
          <v-tab-item value="timelineBalikobot">
             <BalikobotTimeline/>
          </v-tab-item>
          <v-tab-item value="orderRules">
             <OrderRulesTimeline/>
          </v-tab-item>
      </v-tabs-items>

      <v-card  v-if="error && !isLoadingData" class="pa-16 text-center error--text font-weight-bold" :class="error.class">
        {{error.text}}
      </v-card>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { requestToken } from '@/services/api'

  import Overview from '@/components/Overview'
  import OrderTimeline from '@/components/OrderTimeline'
  import BalikobotTimeline from '@/components/BalikobotTimeline'
  import Filters from '@/components/Filters'
  import OrderRulesTimeline from '@/components/OrderRulesTimeline'

  export default {
    name: 'Home',

    components: {
      Overview,
      Filters,
      OrderTimeline,
      BalikobotTimeline,
      OrderRulesTimeline,
    },
    watch: {
      '$route.params.eshopID': function(newVal, oldVal) {
        if(parseInt(oldVal) !== parseInt(newVal)) this.fetchData();
      },
      '$route.params.orderID': function() {
        this.fetchData();
      },
    },
    mounted() {
      this.fetchData();
      this.tab = this.$route.query.tab;
    },
    data: () => ({
      tab: null,
      isLoadingData: false,
      error: null,
    }),
    computed: {
      ...mapGetters(['getEshopHistory', 'getBalikobotAlerts', 'getBalikobotData', 'getEshopModules', 'getInfo', 'getEshops']),
      eshopID() { return this.$route.params.eshopID },
      orderID() { return this.$route.params.orderID },
      currentEshopName() {
        if(this.getInfo.eshops){
          const index = this.getInfo.eshops.map(e => e.id).indexOf(parseInt(this.eshopID));
          const eshop = this.getInfo.eshops[index];
          if(eshop) return eshop.name;
          else return "";
        } else {
           return "";
        }
      },
      getTrackingDisabled() {
        return this.getBalikobotAlerts.tracking_number_missing || this.getBalikobotData === null;
      },
      activeTab: {
        set(tab) {
            let query = {...this.$route.query};
            query.tab = tab;
            this.$router.replace({query: query});
        },
        get() {
            return (this.$route.query.tab);
        },
      },
    },

    methods: {
      ...mapActions(['fetchOrderDetail', 'fetchUserInfo']),
      fetchData() {
        this.error = null;
        this.isLoadingData = true;
        this.fetchUserInfo().catch((e) => {
          if(e.status == 403 || e.status == 422) this.renewToken();
        });

        if(this.orderID) {
          this.fetchOrderDetail({eshopID: this.eshopID, orderID: this.orderID}).then(() => {
            this.isLoadingData = false;
            document.title = `Objednávka ${this.orderID} - ${this.currentEshopName} - brani.cz`;
          }).catch((e) => {
            if(e.status === 400) this.error = {class: 'error--text', text: "Objednávka nebyla nalezena"};
            else if(e.response) this.error = {class: 'error--text', text: `Nastala chyba: ${e.response.data.msg}`};
            else this.error = {class: 'error--text', text: `Nastala neznámá chyba`};
            this.isLoadingData = false;
          });
        } else {
          this.isLoadingData = false;
          this.error = {class: 'info--text', text: "Nejprve prosím zadejte číslo hledané objednávky"};
        }
      },

      renewToken() {
        if(process.env.NODE_ENV !== 'development')
          requestToken().then(() =>  window.location.replace(window.location.href))
                        .catch(() => this.redirectToISLogin())
      },

      redirectToISLogin() {
        const base64URI = btoa(window.location.href);
        window.location.replace(`https://is.brani.cz/client/login?b64url=${base64URI}`);
      },
    }
  }
</script>

<style media="screen">
  .card-list {
    box-shadow: 0 2px 5px -1px rgba(50,50,93,.25),0 1px 3px -1px rgba(0,0,0,.3);
    border-radius: 3px;
    background-color: white;
  }
</style>
