<template>
  <div class="pa-5">
    <div>
      <v-switch v-model="displayRaw" label="RAW DATA" class="d-block"/>
      <div v-if="displayRaw">
        <vueJsonCompare class="json-data" :oldData="getOrderData" :newData="getOrderData"/>
      </div>

      <OrderItems v-if="!displayRaw" :products="getOrderData.items"/>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import vueJsonCompare from 'vue-json-compare'
  import OrderItems from '@/components/Order/OrderItems'

  export default {
    name: "OrderDetail",
    components: {
      vueJsonCompare,
      OrderItems,
    },
    data: () => ({
      displayRaw: false,
    }),
    computed: {
      ...mapGetters(['getOrderDetail', 'getItems']),
      getOrderData () {
        return this.getOrderDetail.data || {}
      },
    },
    methods: {
      joinStockInfo(code) {
        const index = this.getItems.map(i => i.variant_code).indexOf(code);
        if(index > -1) return this.getItems[index].stock_order_time;
      }
    }
  }
</script>

<style scoped>
  .json-data >>> .c-json-p  {
    margin: 0px;
  }

  .json-data >>> .c-json-string {
    color: #43A047;
  }
  .json-data >>> .c-json-key {
    color: #00B0FF;
  }

  .json-data >>> .c-json-outter {
      margin-bottom: -40px;
      margin-top: -30px;
  }
</style>
