import { get } from '@/services/api'

const state = {
  orderDetail: {}, // Current order
  eshopHistory: [], // Shoptet history
  items: [], // Items within the current order
  balikobotData: {}, // Tracking data
  balikobotAlerts:  {}, // As it says
  balikobotStatus: {},
  dbHistory: {}, // Changes made in DB to the order/tracking
  info: {}, // Info about the user logged in
  availableEshops: [], // Array for the select -> basically info but formated for select
  shippingMethods: [], // Available shipping methods for Balikobot
  eshopModules: {}, // Modules for the eshop
  labelService: null,
};

const getters= {
  getOrderDetail: state => state.orderDetail,
  getEshopHistory: state => state.eshopHistory,
  getItems: state => state.items,
  getBalikobotData: state => state.balikobotData,
  getBalikobotAlerts: state => state.balikobotAlerts,
  getBalikobotStatus: state => state.balikobotStatus,
  getDbHistory: state => state.dbHistory,
  getInfo: state => state.info,
  getEshops: state => state.availableEshops,
  getShippingMethods: state => state.shippingMethods,
  getEshopModules: state => state.eshopModules,
  getLabelService: state => state.labelService,
};

const actions= {
  fetchOrderDetail ({commit}, {eshopID, orderID}) {
    return new Promise((resolve, reject) => {
      get(`/orders/${eshopID}/${orderID}/`).then((res) => {
        commit('SET_DETAIL', res.data);
        resolve();
      }).catch((e) => {
        reject(e)
      })
    });
  },

  fetchUserInfo({commit}) {
    return new Promise((resolve, reject) => {
      get('/info/').then((res) => {
        commit('SET_INFO', res.data);
        resolve();
      }).catch((e) => {
        reject(e)
      })
    });
  },

};

const mutations= {
  SET_DETAIL: (state, data) => {
    state.orderDetail = data.order;
    state.eshopHistory = data.order.shoptet_history;
    state.items = data.items;
    state.dbHistory = data.db_history;
    state.balikobotData = data.balikobot.data[0] ? Object.entries(data.balikobot.data[0]).map(item => item[1]) : null;
    state.balikobotStatus = { reason: data.balikobot.status_reason, code: data.balikobot.status_code };
    state.balikobotAlerts = data.balikobot.alerts;
    state.shippingMethods = data.shipping_methods;
    state.eshopModules = data.modules;
    state.labelService = data.balikobot.stitkova_sluzba_info;
  },
  SET_INFO: (state, data) => {
    const isSuperAdmin = data.client.is_superadmin;
    state.info = data;
    state.availableEshops = data.eshops.map(e => ({
      text: isSuperAdmin === true ? `${e.name} (id: ${e.id})` : e.name, // If superadmin add (id)
      value: e.id
    }))
  },
};



export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
