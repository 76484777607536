<template >
  <div class="text-center">
    <h1>Nejste přihlášeni</h1>
    <a href="https://is.brani.cz">Přihlásit se</a>
  </div>
</template>

<script>
export default {
  name: 'NotLogged',
}
</script>

<style lang="css" scoped>
</style>
