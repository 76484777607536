var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Obsah objednávky:")]),(!_vm.displayRaw)?_c('v-data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":"","hide-default-header":"","sort-by":"code","sort-desc":true,"items":_vm.products,"items-per-page":-1,"item-key":""},scopedSlots:_vm._u([{key:"item.stock_at_order",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.joinPastStockInfo(item.code)),function(stock){return _c('div',{key:stock.id},[_vm._v(" "+_vm._s(stock.name)+" "),_c('strong',[_vm._v(_vm._s(stock.amount)+" ks")])])})}},{key:"item.stock_current",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.joinCurrentStockInfo(item.code)),function(stock){return _c('div',{key:stock.id},[_vm._v(" "+_vm._s(stock.name)+" "),_c('strong',[_vm._v(_vm._s(stock.amount)+" ks")])])})}},{key:"item.availability_at_order",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAvailability(item.code))+" ")]}}],null,false,204723804)},[_c('template',{attrs:{"headers":_vm.headers},slot:"header"},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(h,i){return _c('th',{key:h.text},[(i < 3)?_c('span',[_vm._v(_vm._s(h.text))]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._v(" "+_vm._s(h.text)+" "),_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[(h.value !== 'stock_current')?_c('span',[_vm._v("V čase: "+_vm._s(new Date(_vm.getOrderDetail.stock_synced_at).toLocaleString()))]):_c('span',[_vm._v("V čase: "+_vm._s(new Date().toLocaleString()))])])],1)}),0)])])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }