<template>
  <div class="pa-5">
    <OrderInfo v-if="getOrderDetail !== undefined" :order="getOrderDetail" />
    <OrderDetail v-if="getOrderDetail !== undefined"/>

    <v-row >
      <v-col sm="12" md="6">
        <h2 class="mb-5">Tracking zásilky</h2>
        <v-switch v-model="displayBalikobotRaw" label="RAW DATA" class="ml-5 d-block"/>
        <vueJsonCompare v-if="displayBalikobotRaw" class="json-data" :oldData="getBalikobotData" :newData="getBalikobotData"/>

        <div class="ml-10 error--text font-weight-bold text-center">
          {{balikobotErrorMessage()}}
          <div v-if="!isShipMethodPaired()" class="warning--text caption ml-5">
            <v-icon class="warning--text">mdi-alert-rhombus</v-icon>
            Dopravce není napárovaný v Automatizaci Objednávek
          </div>
        </div>

        <v-timeline align-top dense v-if="getBalikobotData !== null && !displayBalikobotRaw">
          <v-timeline-item
            v-for="(item, i) in getBalikobotData"
            :color="calculateColor(item, i)"
            :key="i"
            :small="i != 0">
            <v-row>
              <v-col cols="3">
                <strong>{{item.date}}</strong>
              </v-col>
              <v-col>
                <strong>{{item.name}}</strong>
                <div class="text-caption">
                  <span v-if="item.status_id_v2">({{item.status_id_v2}})</span>
                  {{item.name_balikobot}}
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
      </v-timeline>


      </v-col>

      <v-col sm="12" md="6">
        <h2 class="mb-5">Historie objednávky</h2>
        <v-data-table
          class="eshop-table"
          :headers="headers"
          :items="getEshopHistory"
          item-key=""
          :items-per-page="-1"
          hide-default-footer
          hide-default-header	>

          <template v-slot:item.creationTime="{ item }">
            {{new Date(item.creationTime).toLocaleString()}}
          </template>
          <template v-slot:item.user="{ item }">
            <div v-if="item.user !== null">
              <p class="mb-0">{{item.user.id}}</p>
              {{item.user.name}}
            </div>
          </template>
          <template v-slot:item.text="{ item }">
            <span v-html="getSanitized(item.text)">
            </span>
          </template>
        </v-data-table>

      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import vueJsonCompare from 'vue-json-compare'
  import OrderInfo from '@/components/Order/OrderInfo'
  import OrderDetail from '@/components/OrderDetail'

  export default {
    name: 'Overview',
    components: {
      OrderInfo,
      OrderDetail,
      vueJsonCompare,
    },
    data: () => ({
      displayBalikobotRaw: false,
      headers: [
        { text: 'Čas', align: 'start', value: 'creationTime',},
        { text: 'Uživatel', value: 'user' },
        { text: 'Poznámka', value: 'text' },
      ],
    }),
    computed: {
      ...mapGetters(['getOrderDetail', 'getBalikobotData', 'getBalikobotAlerts', 'getEshopHistory', 'getBalikobotStatus', 'getLabelService']),
      orderAlerts() {
        return this.getOrderDetail.alerts || {};
      },
    },
    methods: {
      getSanitized(text) {
        return text.replace( /(<([^>]+)>)/ig, '')
                   .replace(/\n/g, ' <br/>');
      },
      calculateColor(item, index) {
        if(item.status_id == 0) return 'red';
        if(index == 0) return 'blue';
        if(index == this.getBalikobotData.length-1) return 'blue';
        return 'grey lighten-3';
      },
      balikobotErrorMessage() {
        const serviceName = this.getLabelService?.service_type || '-neznámá služba-';
        if( this.getBalikobotAlerts.tracking_number_missing === true) return 'Objednávka nemá vyplněno číslo zásilky';
        if( this.getBalikobotStatus.reason === null && serviceName == "balikobot") return `${serviceName} vrátil neznámou chybu`;
        if( this.getBalikobotAlerts.balikobot_not_ok_response === true )
          return `Balikobot vrátil chybu  (${this.getBalikobotStatus.code} - ${this.getBalikobotStatus.reason})`;

        if( this.getBalikobotAlerts.balikobot_api_access_missing === true )  return `Nemáte nastaveny API údaje do služby ${serviceName}`;
        if( this.getBalikobotData === null ) return `Nemáme info ze služby ${serviceName}`;
        return null;
      },
      isShipMethodPaired(){
          if(this.getLabelService) {
            return !this.getBalikobotAlerts.balikobot_delivery_method_not_paired;
          } else {
            return !this.orderAlerts.balikobot_delivery_method_not_paired;
          }
      },

    },

  }
</script>

<style scoped>
  .eshop-table > .v-data-table__wrapper > table > tbody > tr >   td{
    font-size: 14px !important;
  }

  .info-line {
    border-bottom: thin solid #e6e9ed;
  }

  .info-line__title{
    width: 150px;
    font-weight: bold;
  }
  .json-data >>> .c-json-string {
    color: #43A047;
  }
  .json-data >>> .c-json-key {
    color: #00B0FF;
  }
  .json-data >>> .c-json-outter {
      margin-bottom: -40px;
      margin-top: -30px;
  }
  .json-data >>> .c-json-p  {
    margin: 0px;
  }
</style>
