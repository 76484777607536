<template>
  <div class="px-5">
    <v-row>
      <v-col cols="12" sm="4" md="3"><v-autocomplete label="eshop" v-model="eshopID" :items="getEshops" /></v-col>
      <v-col cols="12" sm="4" md="3"><v-text-field label="číslo objednávky" v-model="orderID" @keyup.enter="applyFilters"/></v-col>
      <v-col cols="12" sm="4" md="3"><v-btn class="mt-5" @click="applyFilters">zobrazit</v-btn></v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Filters',
    data: () => ({
      eshopID: null,
      orderID: null,
    }),
    mounted() {
      this.eshopID = parseInt(this.$route.params.eshopID) || this.getEshops[0].value;
      this.orderID = this.$route.params.orderID || null;
    },
    computed: {
      ...mapGetters(['getEshops']),
    },
    methods: {
      applyFilters() {
        this.$router.replace({params: {eshopID: this.eshopID, orderID: this.orderID} })
      }
    },
  }
</script>

<style >

</style>
