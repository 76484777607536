<template>
  <div class="pa-5" v-if="getDbHistory.balikobot !== undefined">
    <v-row>
      <v-col sm="12" md="6">
        <h2 class="mb-5">Tracking zásilky</h2>

        <v-timeline v-if="getDbHistory.balikobot.length > 0" align-top dense class="db-history">
          <v-timeline-item
            v-for="(item, i) in getDbHistory.balikobot"
            :color="activeBalikobot == i ? 'blue' : null"
            :key="i"
            small>
            <v-row @click="selectBalikobotHistory(i)">
              <v-col cols="12" md="3">
                <strong>
                  {{ prettyDatePrint(item.retrieved_at[0]) }}
                  {{ getLastRetrievedTime(item) !== null ? '-' : null }}
                  {{ prettyDatePrint(getLastRetrievedTime(item)) }}
                </strong>
              </v-col>
              <v-col>
                <strong>Stejný stav nastal v časech:</strong>
                <div class="text-caption" v-for="date in item.retrieved_at.slice(0, 5)" :key="date">
                  {{prettyDatePrint(date)}}
                </div>
                <div v-if="item.retrieved_at.length > 5" class="text-caption font-weight-bold">
                + dalších {{item.retrieved_at.length  - 5}}
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>

        <h3 v-if="this.getLabelService" class="my-3 ml-5">Data ze služby {{this.getLabelService.service_type}}:</h3>
        <div v-if="parseBalikobotData() === null" class="ml-10 error--text font-weight-bold">Nemáme info ze služby <span v-if="this.getLabelService">{{this.getLabelService.service_type}}</span></div>
        <v-timeline align-top dense v-if="parseBalikobotData() !== null">
          <v-timeline-item
            v-for="(item, i) in parseBalikobotData()"
            :color="calculateColor(item, i)"
            :key="i"
            :small="i != 0">
            <v-row>
              <v-col cols="12" md="3">
                <strong>{{item.date}}</strong>
              </v-col>
              <v-col>
                <strong>{{item.name}}</strong>
                <div class="text-caption">
                  ({{item.status_id_v2}})
                  {{item.name_balikobot}}
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>

      </v-col>
      <v-col sm="12" md="6">
        <h2 class="mb-5">Raw data</h2>
        <vueJsonCompare class="json-data" :oldData="parseBalikobotData()" :newData="parseBalikobotData()"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import vueJsonCompare from 'vue-json-compare'

  export default {
    name: 'BalikobotTimeline',
    components: {
      vueJsonCompare,
    },
    data: () => ({
      activeBalikobot: 0,
    }),
    mounted() {
      if(this.$route.query.date) this.setHistoryDate();
    },
    computed: {
      ...mapGetters(['getDbHistory', 'getLabelService']),
    },
    methods: {
      calculateColor(item, index) {
        if(item.status_id == 0) return 'red';
        if(index == 0) return 'blue';
        if(index == this.parseBalikobotData().length-1) return 'blue';
        return 'grey lighten-3';
      },

      getRetrieveTimeFromIndex(index) {
        return this.getDbHistory.balikobot ? this.getDbHistory.balikobot[index].response_date : -1;
      },

      prettyDatePrint(date) {
        if(date !== null)
          return new Date(date).toLocaleString();
        else
          return '';
      },
      getLastRetrievedTime(item) {
        if(item.retrieved_at.length <= 1) return null;
        const lastIndex = item.retrieved_at.length -1;
        return item.retrieved_at[lastIndex];
      },
      selectBalikobotHistory(index) {
        this.activeBalikobot = index;
        const date = this.getRetrieveTimeFromIndex(index);
        this.pushDateToUrl(date);
      },

      pushDateToUrl(date) {
        this.$router.replace({query: {...this.$route.query, date: date}});
      },

      setHistoryDate() {
        const date = this.$route.query.date;
        if(this.getDbHistory.balikobot){
          const index = this.getDbHistory.balikobot.findIndex(history => history.response_date == date);
          if(index > -1) this.activeBalikobot = index;
        }
      },

      parseBalikobotData() {
        if(this.getDbHistory.balikobot){
          const selectedData = this.getDbHistory.balikobot[this.activeBalikobot];

          if(typeof selectedData.response[0][0] === 'object') //NICE
            return Object.entries(selectedData.response[0]).map(item => item[1]);
          else
            return null;
        }
      },
    },

  }
</script>

<style scoped>
  .eshop-table > .v-data-table__wrapper > table > tbody > tr >   td{
    font-size: 14px !important;
  }

  .json-data >>> .c-json-string {
    color: #43A047;
  }
  .json-data >>> .c-json-key {
    color: #00B0FF;
  }
  .json-data >>> .c-json-outter {
      margin-bottom: -40px;
      margin-top: -30px;
  }
  .json-data >>> .c-json-p  {
    margin: 0px;
  }

  .info-line {
    border-bottom: thin solid #e6e9ed;
  }

  .info-line__title{
    width: 150px;
    font-weight: bold;
  }

  .db-history .v-timeline-item  {
    cursor: pointer;
  }
  .db-history .v-timeline-item:hover {
    background: #E3F2FD;
  }
</style>
