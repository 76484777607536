import axios from "axios"

const API_URL = process.env.VUE_APP_URL;
const TOKEN_URL = process.env.VUE_APP_TOKEN_URL;
const JWT = localStorage.getItem('authToken');

const authorizationHeader = () => {
      return { 'Authorization' : 'Bearer ' + JWT  };
};

function checkResponse(res) {
    if (res.status < 304) {
        return res;
    } else if (res.status === 401 || res.status === 403) {
        return Promise.reject({status: 403, message: "Uživatel není přihlášen."})
    } else {
        return Promise.reject({status: res.status, payload: res.data});
    }
}

//GET
export function get(uri, headers) {
    const instance = axios.create({
        validateStatus: () => {
            return true;
        },
      });
    return instance.get(API_URL + uri, { headers: { ...authorizationHeader(), ...headers} })
                   .then(checkResponse);
}

export function requestToken() {
    return new Promise((resolve, reject) => {
        axios.get(TOKEN_URL, { withCredentials: true })
             .then((res) => {
               localStorage.setItem('authToken', res.data.jwt);
               resolve(res);
             })
             .catch(() => reject())
    })
}
