<template>
  <div class="pa-5">
    <h2 class="mb-5">Historie aplikovaných pravidel v Automatizaci Objednávek:</h2>
    <v-data-table
      v-if="getEshopModules.order_rules"
      class="eshop-table"
      :headers="headers"
      :items="getEshopModules.order_rules.history"
      item-key=""
      :items-per-page="20"
      :footer-props="{
        'items-per-page-options': [30, -1],
        'items-per-page-text': 'Počet položek na stránku:'
      }">
      <template v-slot:item.datetime="{ item }">
        {{new Date(item.datetime).toLocaleString()}}
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: "OrderRulesTimeline",

    data:() => ({
      headers: [
        { text: 'Čas spuštění', align: 'start', value: 'datetime',},
        { text: 'Název pravidla', value: 'name' },
      ],
    }),
    computed: {
      ...mapGetters(['getEshopModules']),
    },
  }
</script>

<style scoped>

</style>
