<template>
  <div class="pa-5">
    <OrderInfo v-if="getOldData" :order="getOrder"/>
    <OrderItems v-if="getOldData" :products="getOldData.items" class="my-10"/>

    <v-row>
      <v-col sm="12" md="6">
        <h2 class="mb-5">Časová osa změn</h2>
        <span class="text-caption ml-5">Zvolený typ:</span>
        <v-chip class="success mx-5" small>Stav k porovnání</v-chip>
        <v-chip class="error" small>Aktuálně zobrazený</v-chip>
        <v-chip class=" mx-5" small dark @click="swapDates()"><v-icon class="mr-1">mdi-swap-horizontal</v-icon>Prohodit</v-chip>
        <v-timeline
            align-top
            dense>
            <v-timeline-item
              v-for="({alerts, data}, i) in getDbHistory.order"
              class="py-3"
              :color="calculateColor(i)"
              :key="i"
              small>
              <v-row @click="i !== newDataIndex ? setHistoryByIndex(i): null">
                <v-col cols="3">
                  <strong>{{new Date(data.changeTime).toLocaleString()}}</strong>
                </v-col>
                <v-col>
                  <v-icon v-if="hasActiveAlerts(alerts)" class="warning--text mr-1">mdi-alert-rhombus</v-icon><strong>Objednávka upravena</strong>
                  <v-btn
                    v-if="i !== oldDataIndex"
                    class="mr-5 mt-2 float-right error d-none"
                    x-small
                    @click="setHistoryByIndex(i, false)">Aktuální</v-btn>
                  <v-btn
                    v-if="i !== newDataIndex"
                    class="mr-5 mt-2 float-right success d-none"
                    x-small
                    @click="setHistoryByIndex(i, true)">K porovnání</v-btn>
                  <div class="text-caption">
                    {{data.status.name}}
                  </div>

                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline></v-col>
      <v-col sm="12" md="6">
        <h2 class="mb-5">Porovnání změn</h2>
        <vueJsonCompare v-if="getOldData && getNewData" class="json-data" :oldData="getOldData" :newData="getNewData.data"/>
      </v-col>

    </v-row>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import vueJsonCompare from 'vue-json-compare'
  import OrderInfo from '@/components/Order/OrderInfo'
  import OrderItems from '@/components/Order/OrderItems'

  export default {
    name: 'OrderTimeline',
    components: {
      vueJsonCompare,
      OrderInfo,
      OrderItems,
    },
    data: () => ({
      oldDataIndex: 0, //Compare
      newDataIndex: 0, //Default
      forceURLDate: false,
    }),
    mounted() {
      if(this.$route.query.defaultDate || this.$route.query.compareDate) this.setHistoryByDate();
      if(this.getDbHistory.order && !this.$route.query.defaultDate) this.newDataIndex = this.getDbHistory.order.length-1
    },
    computed: {
      ...mapGetters(['getDbHistory']),
      getOrder() {
        return this.getDbHistory.order ? this.getDbHistory.order[this.oldDataIndex] : {};
      },
      getOldData() {
        return this.getDbHistory.order ? this.getDbHistory.order[this.oldDataIndex].data : {};
      },
      getNewData() {
        return this.getDbHistory.order ? this.getDbHistory.order[this.newDataIndex] : {};
      },
    },
    methods: {
      calculateColor(index) {
        if(index == this.newDataIndex) return 'green';
        if(index == this.oldDataIndex) return 'red';
        return 'grey';
      },

      getChangeTimeFromIndex(index) {
        if(this.getDbHistory.order) {
          const orderData = this.getDbHistory.order.map(item => item.data)
          return orderData[index].changeTime;
        } else {
          return null;
        }
      },

      hasActiveAlerts(alerts) {
        const active = Object.values(alerts).filter(entry => entry === true)
        return active.length > 0;
      },

      setHistoryByDate() {
        if(this.getDbHistory.order){
          const newDate = this.$route.query.defaultDate;
          const oldDate = this.$route.query.compareDate;

          if(newDate !== undefined) {
            const defaultOrderIndex = this.getDbHistory.order.map(item => item.data).findIndex(item => item.changeTime == newDate);
            if(defaultOrderIndex !== -1) this.newDataIndex = defaultOrderIndex;
          }

          if(oldDate !== undefined) {
            const oldOrderIndex = this.getDbHistory.order.map(item => item.data).findIndex(item => item.changeTime == oldDate);
            if(oldOrderIndex !== -1) this.oldDataIndex = oldOrderIndex;
          }
        }
      },

      setHistoryByIndex(index, isDefault) {
        if(isDefault){
          this.newDataIndex = index;
          const date = this.getChangeTimeFromIndex(index);
          this.pushDateInURL(date, true);
        } else {
          this.oldDataIndex = index;
          const date = this.getChangeTimeFromIndex(index);
          this.pushDateInURL(date, false);
        }
      },

      pushDateInURL(date, isDefault) {
        if(isDefault)
          this.$router.replace({query: {...this.$route.query, defaultDate: date}});
        else
          this.$router.replace({query: {...this.$route.query, compareDate: date}});
      },

      swapDates() {
        const newTemp = this.newDataIndex;
        this.setHistoryByIndex(this.oldDataIndex, true);
        this.setHistoryByIndex(newTemp, false);
      }

    }
  }
</script>

<style scoped>
  .json-data >>> .c-json-string {
    color: #43A047;
  }
  .json-data >>> .c-json-key {
    color: #00B0FF;
  }
  .json-data >>> .c-json-outter {
      margin-bottom: -40px;
      margin-top: -30px;
  }
  .json-data >>> .c-json-p  {
    margin: 0px;
  }

  .v-timeline-item  {
    cursor: pointer;
  }

  .v-timeline-item:hover {
    background: #E3F2FD;
  }

  .v-timeline-item:hover .v-btn{
    display: block !important;
  }
</style>
